/* Reset some basic styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set the video background */
#background-video {
  position: fixed;
  top: 0;   /* Cover from the top */
  left: 0;  /* Cover from the left */
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure video is behind other content */
  opacity: 0.5; /* Adjust for desired brightness */
  object-fit: cover; /* Ensures the video covers the entire background */
  pointer-events: none; /* Allows clicking through the video */
}


/* Set a comfortable font for the application */
body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #ffffff;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Container to hold the card content */
.App {
  position: relative; /* Ensures content appears over video */
  background-color: rgba(37, 42, 65, 0.9); /* Dark with slight transparency */
  padding: 40px 30px;
  border-radius: 15px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 500px;
  text-align: center;
  margin: auto;
  overflow: hidden; /* Ensure content stays within card bounds */
  word-wrap: break-word; /* Ensure long text wraps properly */
}

/* Heading Styles */
.App h1 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #ffffff; /* Light color for text */
}

/* Paragraph Styles */
.App p {
  margin-bottom: 15px;
  font-size: 1rem;
  color: #b0bec5; /* Light grey for supporting text */
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  white-space: nowrap; /* Prevents address from spilling over */
}

/* Wallet Button Styles */
.WalletMultiButton {
  margin: 20px 0;
}

/* Button Styles */
button, .verify-button {
  padding: 12px 20px;
  background-color: #4CAF50; /* Green */
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 250px;
  margin-top: 10px;
}

button:hover, .verify-button:hover {
  background-color: #45a049;
}

button:disabled {
  background-color: #a5d6a7;
  cursor: not-allowed;
}

/* Custom Verify Button */
.verify-button {
  background-color: #3498db; /* Blue */
}

.verify-button:hover {
  background-color: #2980b9;
}

/* Success and Error Messages */
.success-message {
  color: #27ae60;
  background-color: #e8f5e9;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.error-message {
  color: #c0392b;
  background-color: #fdecea;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
}

/* Responsive Design */
@media (max-width: 480px) {
  .App {
    padding: 30px 20px;
  }

  .App h1 {
    font-size: 1.5rem;
  }

  button {
    font-size: 0.9rem;
    padding: 10px 18px;
  }
}

